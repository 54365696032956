<template>
  <div>
    <b-card>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >               

          <!-- FORM DEVEDOR -->
          <!-- Nome -->
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome *"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="clienteData.nome"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="João da Silva"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tipo Pessoa e Documento -->
          <b-row>
            <b-col>
              <!-- Tipo Pessoa -->
              <validation-provider
                #default="validationContext"
                name="Tipo Pessoa"
                rules="required"
              >
                <b-form-group
                  label="Tipo Pessoa *"
                  label-for="tipo_pessoa"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="clienteData.tipo_pessoa"
                    :options="tipoPessoaOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="tipo_pessoa"
                    state="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Documento -->
              <validation-provider
                #default="validationContext"
                name="Documento"
                rules="required"
              >
                <b-form-group
                  label="Documento *"
                  label-for="documento"
                >
                  <b-form-input
                    id="documento"
                    v-model="clienteData.documento"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>    
            </b-col>
          </b-row>

          <!-- Código -->
          <validation-provider
            #default="validationContext"
            name="Codigo"
            rules="alpha-num"
          >
            <b-form-group
              label="Codigo Interno"
              label-for="codigo"
            >
              <b-form-input
                id="codigo"
                v-model="clienteData.codigo"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group> 
          </validation-provider>

          <!-- Telefone -->
          <validation-provider
            #default="validationContext"
            name="Telefone"
            rules=""
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="clienteData.telefone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group> 
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="clienteData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group> 
          </validation-provider> 

          <hr class="mt-2">
          <h6>Endereço</h6>

          <!-- CEP e Logradouro -->
          <b-row>
            <b-col md="3">
              <!-- CEP -->
              <validation-provider
                #default="validationContext"
                name="CEP"
                rules=""
              >
                <b-form-group
                  label="CEP"
                  label-for="cep"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="cep"
                    v-model="clienteData.cep"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="9">
              <!-- Logradouro -->
              <validation-provider
                #default="validationContext"
                name="Logradouro"
                rules=""
              >
                <b-form-group
                  label="Logradouro"
                  label-for="logradouro"
                >
                  <b-form-input
                    id="logradouro"
                    v-model="clienteData.logradouro"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>    
            </b-col>
          </b-row>

          <!-- Numero / Complemento / Bairro -->
          <b-row>
            <b-col>
              <!-- Numero -->
              <validation-provider
                #default="validationContext"
                name="Numero"
                rules=""
              >
                <b-form-group
                  label="Numero"
                  label-for="numero"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="numero"
                    v-model="clienteData.numero"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Complemento -->
              <validation-provider
                #default="validationContext"
                name="Complemento"
                rules=""
              >
                <b-form-group
                  label="Complemento"
                  label-for="complemento"
                >
                  <b-form-input
                    id="complemento"
                    v-model="clienteData.complemento"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>    
            </b-col>
            <b-col>
              <!-- Bairro -->
              <validation-provider
                #default="validationContext"
                name="Bairro"
                rules=""
              >
                <b-form-group
                  label="Bairro"
                  label-for="bairro"
                >
                  <b-form-input
                    id="bairro"
                    v-model="clienteData.bairro"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>    
            </b-col>
          </b-row>

          <!-- Estado e Cidade -->
          <b-row>
            <b-col>
              <!-- Estado -->
              <validation-provider
                #default="validationContext"
                name="Estado"
                rules=""
              >
                <b-form-group
                  label="Estado"
                  label-for="estado"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="clienteData.uf_id"
                    :options="estadosOptions"
                    :reduce="val => val.id"
                    input-id="estado"
                    label="nome"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Cidade -->
              <validation-provider
                #default="validationContext"
                name="Cidade"
                rules=""
              >
                <b-form-group
                  label="Cidade"
                  label-for="cidade"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="clienteData.cidade_id"
                    :options="cidadesOptions"
                    :reduce="val => val.id"
                    input-id="cidade"
                    label="nome"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>


                  
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name:'cliente-credor' }"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>     
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BRow,BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref,onBeforeMount,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import axios from '@axios'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email
    }
  },
  setup(props, { emit }) {
    const blankClienteData = {
      nome: null,
      tipo_pessoa: 'PF',
      documento: null,
      codigo: null,
      situacao: 'ATIVO',
      telefone: null,
      email: null,
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      complemento: null,
      uf_id: null,
      cidade_id: null,
    }

     const tipoPessoaOptions = [
      { label: 'Pessoa Física', value: 'PF' },
      { label: 'Pessoa Jurídica', value: 'PJ' }
    ]

    const estadosOptions = ref([])
    const cidadesOptions = ref([])

    const clienteData = ref(JSON.parse(JSON.stringify(blankClienteData)))

    const resetclienteData = () => {
      clienteData.value = JSON.parse(JSON.stringify(blankClienteData))
    }

    const fetchCidades = (id) => {
      axios
      .get('estado/'+id+'/cidades')
      .then(response => {
        cidadesOptions.value = response.data
      })
      .catch(error => reject(error))  
    }

    onBeforeMount(() => {
      axios
      .get('clientes/'+router.currentRoute.params.id)
      .then(response => {
        const { data } = response.data  
        clienteData.value = data
      })
      .catch(error => reject(error))  

      axios
      .get('estados')
      .then(response => {
        estadosOptions.value = response.data
      })
      .catch(error => reject(error))  
    })

    watch(clienteData, (cliente, prevCliente) => {
      if(cliente.uf_id != undefined || cliente.uf_id != null){
        fetchCidades(cliente.uf_id)
      }
    })

    function onSubmit() {
     axios
      .put('clientes/'+router.currentRoute.params.id,{
        ...clienteData.value
      })
      .then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar devedor',
            icon: 'UserIcon',
            variant: 'success',
            text: `Alterado com sucesso!`,
          },
        })
        // router.push({ name: 'cliente-list' })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar',
            icon: 'XIcon',
            variant: 'danger',
            text: `Algo deu errado ):`,
          },
        })
      })  
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclienteData)

    return {
      clienteData,
      onSubmit,
      tipoPessoaOptions,
      estadosOptions,
      cidadesOptions,

      fetchCidades,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
